<template>
    <div class="mypage search">
        <div class="search">
            <form action="/">
              <van-search
                clearable
                v-model="keywords"
                show-action
                placeholder="请输入订单编号"
                @search="onSearch"
                >
                  <!-- <template #action>
                      <div @click.prevent="onSearch">搜索</div>
                    </template> -->
                </van-search>
            </form>
        </div>
        <div class="order-list inner-box" >
            <div class="" v-if="orderlist.length">
                <order-item :datalist="orderlist"></order-item>
            </div>
            <div class="empty" v-else>
                暂无数据
            </div>
        </div>
    </div>
</template>

<script>
    import { toast, checkLogin} from '../../utils/common.js';
    import OrderItem from '../../components/OrderItem.vue';
    import { searchErpOrderList } from '../../api/task.js';
    export default{
        components:{
          OrderItem
        },
        data() {
            return{
                keywords: '',
                orderlist: [],
            }
        },
        methods:{
            onSearch(val){
                if(!val){
                   return; 
                }
                checkLogin().then(()=> {
                    searchErpOrderList(val).then((ret)=>{
                        var list = ret.data;
                        this.orderlist = list
                    }).catch((err)=>{
                        toast(err.message || '搜索失败！')
                    })
                })
                
            },
            onclear(){
                this.keywords = '';
            },
        },
    }
</script>

<style scoped>
    .order-list, .inner-box{
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 14px;
    }
</style>