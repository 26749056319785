<template>
    <div class="list">
        <div class="detail-item" v-for="(item, index) in datalist" :key="index">
            <router-link
              :to="`/erp/order-detail/${item.id}`"
             >
                <van-row>
              <!-- <van-col span="6">{{getCateName(item.category)}}</van-col> -->
              
                  <van-col span="10" style="font-family: bold;">
                    <span class="link">
                        {{item.order_no}}
                    </span>
                  </van-col>
                 <van-col span="14" style="font-size: 13px;color: #666; text-align: right;">
                      下单日期：{{item.order_date}}
                  </van-col>
                </van-row>
                <van-row class="item-line">
                  <van-col span="24">{{item.customer_name}}</van-col>
                  <!-- <van-col span="4" class="item-num">{{item.num}}</van-col> -->
                </van-row>
                <van-row class="item-line" v-if="item.remark">
                  <van-col span="24">{{item.remark}}</van-col>
                </van-row>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default{
        props:[
            'datalist'
        ],
        methods:{
            
        },
    }
</script>

<style scoped>
    .detail-item{
        margin-top: 0.5rem;
        margin-bottom: 0.8rem;
        margin-left: 1px;
        margin-right: 1px;
        font-size: 16px;
        padding: 0.8rem;
        line-height: 3.4rem;
        border-radius: 3px;
        /* font-weight: bold; */
        -webkit-box-shadow: 0 0 1px 1px #ddd ;
        box-shadow: 0 0 1px 1px #ddd  ;
        text-align: left;
    }
    .detail-item .btn-box{
        display: flex;
        justify-content: space-around;
    }
    .detail-item .item-num{
        font-weight: bold;
        text-align: center;
        font-size: 18px;
    }
    .item-line{
        border-top: 1px dashed #eee;
    }
    .link{
        font-size: 14px;
        color: #576b95;
        text-decoration: underline;
    }
</style>